.cfl:first-letter {
  text-transform: capitalize;
}
.ric-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
}

body {
  overflow-x: hidden;
  position: relative;
}